$.noConflict();

jQuery(document).ready(function($){

    // Funciones del template
    var templateFunctions = {

      // Init all functions
      init: function() {

        // Smooth Scroll
        templateFunctions.smoothScroll();

        // mmenu
        // templateFunctions.mainNav();

        // Slider Full Window
        templateFunctions.sliderFullWindow();

        // Video Full Window
        // templateFunctions.videoFullWindow();

        // Slick carousel
        templateFunctions.slickCarousel();

        // ParollerEffect
        templateFunctions.parollerEffect();

        // AOS animations
        templateFunctions.animations();

        // Stiky Kit
        templateFunctions.stikyElements();

        // Light Gallery
        templateFunctions.lightGallery();

        // Block Right click
        // templateFunctions.blockRightClick();

        // Lazy Load
        templateFunctions.jqueryLazyLoad();

        // Tooltips
        templateFunctions.tooltips();

        // Please wait
        templateFunctions.pleaseWait();
      },

      // Please wait
      pleaseWait: function() {
        $(document).ready(function () {
            if ( window.loading_screen ) {
              window.loading_screen.finish();
            }
        });
      },

      // Smooth scroll
      smoothScroll: function() {
        var scroll = new SmoothScroll('.smooth-scroll', {
        	// Selectors
        	ignore: '.no-smooth-scroll', // Selector for links to ignore (must be a valid CSS selector)
        	// header: null, // Selector for fixed headers (must be a valid CSS selector)

        	// Speed & Easing
        	speed: 1000, // Integer. How fast to complete the scroll in milliseconds
        	offset: 0, // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
        	easing: 'easeOutQuad', // Easing pattern to use
        	// customEasing: function (time) {}, // Function. Custom easing pattern

        	// Callback API
        	// before: function () {}, // Callback to run before scroll
        	// after: function () {} // Callback to run after scroll
        });
      },

      // Main Nav
      mainNav: function() {
        var $menu = $("#main-menu").mmenu({
           // wrappers: ["bootstrap4"]
        }, {
           // configuration
            offCanvas: {
              pageSelector: "#page-wrapper"
            },
            classNames: {
              fixedElements: {
                 fixed: "fixed",
                 stiky: "stiky",
              }
            },
            fixedElements: {
              elemInsertSelector: "#page-wrapper",
            },
        });
        var $icon = $(".navbar__toggler .hamburger");
        var API = $menu.data( "mmenu" );

        $icon.on( "click", function() {
           API.open();
        });

        API.bind( "open:finish", function() {
           setTimeout(function() {
              $icon.addClass( "is-active" );
           }, 100);
        });
        API.bind( "close:finish", function() {
           setTimeout(function() {
              $icon.removeClass( "is-active" );
           }, 100);
        });
      },

      // Slider Full Window
      sliderFullWindow: function() {
        $('#slider-fullwindow').sliderPro({
          width: '100%',
          height: '100%',
          arrows: true,
          buttons: false,
          waitForLayers: true,
          fade: true,
          fadeDuration: 1500,
          autoplay: true,
          loop: false,
          autoScaleLayers: true,
          forceSize: 'fullWindow',
        });
      },

      // Video Full Window
      videoFullWindow: function () {
          // $('#bg-video').vide({
          //     mp4: "/themes/uno/assets/dist/videos/intro-background.mp4",
          //     webm: "/themes/uno/assets/dist/videos/intro-background.webm",
          //     ogv: "/themes/uno/assets/dist/videos/intro-background.ogv",
          //     poster: "/themes/uno/assets/dist/videos/intro-background.jpg",
          // }, {
          //     volume: 1,
          //     playbackRate: 1,
          //     muted: true,
          //     loop: true,
          //     autoplay: true,
          //     position: '50% 50%', // Similar to the CSS `background-position` property.
          //     posterType: 'jpg', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
          //     resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
          //     bgColor: '#0e1111', // Allow custom background-color for Vide div,
          //     className: 'bg-video', // Add custom CSS class to Vide div
          // });
      },

      // Slick Carousel
      slickCarousel: function() {
        $('.partners-carousel').slick({
          mobileFirst: true,
          dots: false,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
              }
            },
          ]
        });
        $('.features-carousel').slick({
          mobileFirst: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
          ]
        });
        $('.properties-carousel, .destinations-carousel').slick({
          mobileFirst: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
          ]
        });
        $('.concierge-carousel, .experiences-carousel').slick({
          mobileFirst: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
          ]
        });
        $('.testimonials-carousel').slick({
          mobileFirst: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
        $('.landing-carousel').slick({
          arrows: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
        });
      },

      // Parallax Effect
      parollerEffect: function() {
        $(".parallax").paroller({
          factor: '0.2',
          type: 'background',
          direction: 'vertical',
        });
      },

      // AOS Animations
      animations: function() {
        AOS.init();
      },

      // Stiky
      stikyElements: function() {
        // Sticky kit
        var window_width = $( window ).width();
        // Si la ventana al cargar por primera vez es menor a 768 px retira el efecto
        if (window_width < 768) {
          $("#sticky-element").trigger("sticky_kit:detach");
        // Si la ventana es mayor a 768 activa el efecto
        } else {
          make_sticky();
        }
        // Si la ventana cambia de tamaño se ejecuta la comprobación nuevamente.
        $( window ).resize(function() {
          window_width = $( window ).width();
          if (window_width < 768) {
            $("#sticky-element").trigger("sticky_kit:detach");
          } else {
            make_sticky();
          }
        });
        // Inicia el plugin
        function make_sticky() {
          $(".sticky-element").stick_in_parent({
            offset_top: 50
          });
        }
      },

      // Light Gallery
      lightGallery: function() {
        $(".gallery").lightGallery({
          selector: '.gallery__item',
          showThumbByDefault: false,
          subHtmlSelectorRelative: true,
          counter: false,
          download: true,
          zoom: false,
          fullScreen: true,
          autoplayControls: false,
          share: true,
          hideBarsDelay: 2000,
          pullCaptionUp: false,
          // galleryId: 'weddings-portfolio',
        });
        $(".carousel-gallery").lightGallery({
          selector: '.carousel-gallery__item',
          showThumbByDefault: false,
          subHtmlSelectorRelative: true,
          counter: false,
          download: false,
          zoom: false,
          fullScreen: true,
          autoplayControls: false,
          share: true,
          hideBarsDelay: 2000,
          pullCaptionUp: false,
          lazyLoad: 'ondemand',
          // galleryId: 'weddings-portfolio',
        });
      },

      // Jquery Lazy
      jqueryLazyLoad: function() {
        $('.lazy').Lazy({
            // your configuration goes here
            // scrollDirection: 'vertical',
            effect: 'fadeIn',
            // delay: 2000,
            // placeholder: "data:image/gif;base64,R0lGODlhEALAPQAPzl5uLr9Nrl8e7...",
            visibleOnly: true,
            // onError: function(element) {
            //     console.log('error loading ' + element.data('src'));
            // }
        });
      },

      tooltips: function() {
          $('[data-toggle="tooltip"]').tooltip()
      },

      blockRightClick: function() {
          $(document).ready(function(event) {
            if(event.keyCode==123){
              return false;
            }
            else if(event.ctrlKey && event.shiftKey && event.keyCode==73){
              return false;  //Prevent from ctrl+shift+i
            }
            $("body").on("contextmenu",function(){
               return false;
            });
            document.onkeydown = function(e) {
                if (e.ctrlKey &&
                    (e.keyCode === 73 ||
                     e.keyCode === 85 ||
                     e.keyCode === 117 ||
                     e.keyCode === 123 )) {
                    alert('Esta acción no está permitida.');
                    return false;
                } else {
                    return true;
                }

            };
            $('body').on('dragstart', function(event) { event.preventDefault(); });
          });
      }

    };// < / Template functions

    // Init all template functions
    templateFunctions.init();


});
